
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import RepairPart from '@/domain/Repair/RepairPart';
import RepairPartInventory from '@/domain/Repair/RepairPartInventory';
import Location from '@/domain/Location';
import RepairPartService from '@/services/RepairPartService';
import OrbisCard from '@/components/OrbisCard.vue';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import router from '@/router';
import useValidator from '@/validation/useValidator';
import BTab from '@/components/bootstrap-library/BTab.vue';
import BTabs from '@/components/bootstrap-library/BTabs.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import Screen from '@/components/layout/Screen.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import NumberInput from '@/components/inputs/NumberInput.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import { TableDefinition } from '@/types';
import MasterDataStore from '@/modules/master-data/store/MasterDataStore';
import { StaticLocationType } from '@/domain/LocationType';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import EntityType from '@/domain/enums/EntityTypes';
import { useNotification } from '@/composable/useNotifications';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';

type State = {
    repairPart: RepairPart;
    loading: boolean;
    repairPartInventoryList: Array<RepairPartInventory>;
    showInventoryModal: boolean;
    inventoryModalTitle: string;
    repairPartInventory: RepairPartInventory;
    serviceCenterLocations: Array<Location>;
};

export default defineComponent({
    name: 'edit-repair-part-screen',
    components: {
        Screen,
        OrbisCard,
        BTabs,
        BTab,
        TextInput,
        NumberInput,
        BFormInput,
        BCol,
        DropdownAutocompleteSingleSelect,
        BSpinner,
    },
    props: {
        repairPartId: {
            type: String,
            required: false,
            default: undefined,
        },
    },
    setup(props) {
        const repairPartService = new RepairPartService();
        const { locationStore } = MasterDataStore.getInstance();
        const notification = useNotification();

        const { validateForm, validationResult, clearResults } = useValidator<RepairPart>('repair-part');
        const { validateForm: inventoryValidateForm, validationResult: inventoryValidationResult, clearResults: inventoryClearResults } = useValidator<RepairPartInventory>('repair-part-inventory');

        const state = reactive<State>({
            repairPart: new RepairPart(),
            loading: true,
            repairPartInventoryList: [],
            showInventoryModal: false,
            inventoryModalTitle: '',
            repairPartInventory: new RepairPartInventory(),
            serviceCenterLocations: locationStore.locations.filter((loc) => loc.type.staticTypeIdentifier === StaticLocationType.ServiceCenter),
        });

        const navbarTitle = computed(() => (state.repairPart?.id ? `${state.repairPart.partNumber}` : getTitleCaseTranslation('core.button.addRepairPart')));

        const inventoryTable = computed(
            (): TableDefinition<RepairPartInventory> => ({
                items: state.repairPartInventoryList,
                key: 'repair-part-inventory-table',
                name: getTitleCaseTranslation('core.domain.repairPartInventory'),
                columnDefinition: [
                    {
                        key: 'locationName',
                        label: getTitleCaseTranslation('core.domain.location'),
                        searchable: true,
                    },
                    {
                        key: 'quantity',
                        label: getTitleCaseTranslation('core.domain.quantity'),
                    },
                ],
            }),
        );

        async function getInventory() {
            if (state.repairPart.id) {
                state.repairPartInventoryList = ((await repairPartService.getRepairPartInventoryByRepairPartId(state.repairPart.id)) ?? []).map((x) => {
                    x.locationName = state.serviceCenterLocations.find((y) => y.id === x.locationId)?.name ?? '';
                    return x;
                });
                state.repairPartInventoryList.sort((a, b) => (a.locationName > b.locationName ? 1 : -1));
            }
        }

        onBeforeMount(async () => {
            clearResults();
            inventoryClearResults();

            state.loading = true;
            if (props.repairPartId) {
                state.repairPart = (await repairPartService.getRepairPartById(parseInt(props.repairPartId, 10))) ?? new RepairPart();
                await getInventory();
            }
            state.loading = false;
        });

        async function saveRepairPart() {
            validateForm(state.repairPart);
            if (validationResult.isValid) {
                state.loading = true;
                const response = await (state.repairPart.id ? repairPartService.updateRepairPart(state.repairPart) : repairPartService.addRepairPart(state.repairPart));
                if (response.success) {
                    state.repairPart.id = response.id;
                }
                state.loading = false;
            }
        }

        function goToRepairPartList() {
            router.push({ name: MasterDataRouteTypes.REPAIR_PART.LIST });
        }

        function openInventoryModal(repairPartInventory?: RepairPartInventory) {
            inventoryClearResults();
            state.inventoryModalTitle = getTitleCaseTranslation('core.button.add');
            if (repairPartInventory) {
                state.inventoryModalTitle = getTitleCaseTranslation('core.button.edit');
                state.repairPartInventory = new RepairPartInventory(repairPartInventory);
            } else {
                state.repairPartInventory = new RepairPartInventory();
                state.repairPartInventory.repairPartId = state.repairPart?.id;
            }
            state.showInventoryModal = true;
        }

        function selectInventoryLocation(location?: Location) {
            state.repairPartInventory.locationId = location?.id ?? 0;
            state.repairPartInventory.locationName = state.serviceCenterLocations.find((x) => x.id === location?.id)?.name ?? '';
        }

        async function clearInventoryLocation(): Promise<boolean> {
            state.repairPartInventory.locationName = '';
            state.repairPartInventory.locationId = 0;
            return true;
        }

        async function saveInventory() {
            inventoryValidateForm(state.repairPartInventory);
            if (!validationResult.isValid) {
                return;
            }

            if (state.repairPartInventoryList.find((x) => x.locationId === state.repairPartInventory.locationId && x.id !== state.repairPartInventory.id)) {
                notification.showError(getTranslation('core.validation.serviceCenterAlreadyHasInventory'));
                return;
            }

            state.loading = true;
            const response = await (state.repairPartInventory.id
                ? repairPartService.updateRepairPartInventory(state.repairPartInventory)
                : repairPartService.addRepairPartInventory(state.repairPartInventory));
            if (response.success) {
                state.showInventoryModal = false;
                getInventory();
            }
            state.loading = false;
        }

        async function openRepairPartInventoryHistory(repairPartInventory: RepairPartInventory) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.REPAIR_PART_INVENTORY, entityId: repairPartInventory.id },
            });
        }

        return {
            state,
            goToRepairPartList,
            saveRepairPart,
            validationResult,
            inventoryValidationResult,
            navbarTitle,
            getTitleCaseTranslation,
            saveInventory,
            inventoryTable,
            openInventoryModal,
            getTranslation,
            selectInventoryLocation,
            clearInventoryLocation,
            openRepairPartInventoryHistory,
        };
    },
});
