import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce25a0ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "repair-parts-content container-fluid" }
const _hoisted_2 = { class: "repair-parts-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_orbis_card = _resolveComponent("orbis-card")!
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    id: "edit-repair-part-screen",
    title: _ctx.navbarTitle,
    full: "",
    "go-back": _ctx.goToRepairPartList
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_b_button, {
          variant: "light",
          onClick: _ctx.goToRepairPartList
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_b_button, {
          class: "button-save",
          variant: "primary",
          onClick: _ctx.saveRepairPart
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_b_tabs, { class: "repair-parts-tabs" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_tab, {
              title: _ctx.getTitleCaseTranslation('core.domain.details')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_orbis_card, {
                  title: _ctx.getTitleCaseTranslation('core.domain.details')
                }, {
                  default: _withCtx(() => [
                    (!_ctx.state.loading)
                      ? (_openBlock(), _createBlock(_component_b_form, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_text_input, {
                                  modelValue: _ctx.state.repairPart.partNumber,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.repairPart.partNumber) = $event)),
                                  label: _ctx.getTitleCaseTranslation('core.domain.partNumber'),
                                  md: "6",
                                  loading: _ctx.state.loading,
                                  error: _ctx.validationResult?.model.partNumber,
                                  autofocus: ""
                                }, null, 8, ["modelValue", "label", "loading", "error"]),
                                _createVNode(_component_text_input, {
                                  modelValue: _ctx.state.repairPart.description,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.repairPart.description) = $event)),
                                  label: _ctx.getTitleCaseTranslation('core.domain.description'),
                                  md: "6",
                                  loading: _ctx.state.loading,
                                  error: _ctx.validationResult?.model.description
                                }, null, 8, ["modelValue", "label", "loading", "error"]),
                                _createVNode(_component_number_input, {
                                  modelValue: _ctx.state.repairPart.reorderPoint,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.repairPart.reorderPoint) = $event)),
                                  label: _ctx.getTitleCaseTranslation('core.domain.reorderPoint'),
                                  md: "6",
                                  loading: _ctx.state.loading,
                                  error: _ctx.validationResult?.model.reorderPoint
                                }, null, 8, ["modelValue", "label", "loading", "error"]),
                                _createVNode(_component_b_col, { cols: "6" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_form_input, {
                                      modelValue: _ctx.state.repairPart.billingPrice,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.repairPart.billingPrice) = $event)),
                                      label: _ctx.getTitleCaseTranslation('core.domain.billingPrice'),
                                      loading: _ctx.state.loading,
                                      error: _ctx.validationResult?.model.billingPrice,
                                      "number-options": { allowsDecimal: true },
                                      type: "number"
                                    }, null, 8, ["modelValue", "label", "loading", "error"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_b_spinner, { key: 1 }))
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_b_tab, {
              title: _ctx.getTitleCaseTranslation('core.domain.repairPartInventory')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_advanced_table, {
                  "table-array": [_ctx.inventoryTable],
                  "allow-overflow": "",
                  "sticky-header": "500px",
                  style: {"margin":"20px","font-size":"13px"},
                  "dynamic-columns": ""
                }, {
                  toprow: _withCtx(() => [
                    _createVNode(_component_b_button, {
                      size: "sm",
                      variant: "tertiary",
                      disabled: !_ctx.state.repairPart?.id,
                      onClick: _ctx.openInventoryModal
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.add')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]),
                  action: _withCtx((row) => [
                    _createVNode(_component_b_dropdown, {
                      "no-caret": "",
                      "close-on-click": ""
                    }, {
                      "button-content": _withCtx(() => [
                        _createVNode(_component_faicon, { icon: "ellipsis-h" })
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_b_dropdown_item, {
                          onClick: () => _ctx.openInventoryModal(row.item)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_b_dropdown_item, {
                          onClick: () => _ctx.openRepairPartInventoryHistory(row.item)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.showHistory')), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["table-array"])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showInventoryModal,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.showInventoryModal) = $event)),
        title: _ctx.state.inventoryModalTitle,
        centered: "",
        size: "xl"
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.loading,
            onClick: _ctx.saveInventory
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
              (_ctx.state.loading)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_dropdown_autocomplete_single_select, {
                "model-value": _ctx.state.repairPartInventory.locationName,
                loading: _ctx.state.loading,
                cols: "6",
                data: _ctx.state.serviceCenterLocations,
                "search-by": "name",
                "empty-text": _ctx.getTranslation('core.common.noLocations'),
                "display-name": "name",
                label: _ctx.getTitleCaseTranslation('core.domain.location'),
                error: _ctx.inventoryValidationResult.model.locationId,
                "on-before-clear": _ctx.clearInventoryLocation,
                onOnSelect: _ctx.selectInventoryLocation
              }, null, 8, ["model-value", "loading", "data", "empty-text", "label", "error", "on-before-clear", "onOnSelect"]),
              _createVNode(_component_b_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    modelValue: _ctx.state.repairPartInventory.quantity,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.repairPartInventory.quantity) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.quantity'),
                    loading: _ctx.state.loading,
                    error: _ctx.inventoryValidationResult?.model.quantity,
                    "number-options": { allowsDecimal: true },
                    type: "number"
                  }, null, 8, ["modelValue", "label", "loading", "error"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "title"])
    ]),
    _: 1
  }, 8, ["title", "go-back"]))
}